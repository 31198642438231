import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { setToken } from '../request/auth';
import { useUser } from '../context/userContext'
import { getUser } from '../request/settings';

const useQueryTokenAuth = () => {
  const [authToken, setAuthToken] = useState('');
  const { setUser } = useUser();
  const router = useRouter();
  const query = router.query;

  useEffect(() => {
    if (Object.keys(query).length !== 0) {
      if (query.token) {
        setToken(query.token);
        setAuthToken(query.token);
        getUser().then((data) => setUser(data));
      }
    }
  }, [query]);

  return authToken;
};

export default useQueryTokenAuth;
