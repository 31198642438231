import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import axios from "axios";
import FormOfferFast from "./offers/FormOfferFast";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 750,
    height: 600,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 15,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: "auto",
  },
  form: {
    marginBottom: "20px",
  },
  input: {
    width: "100%",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    marginBottom: "15px",
  },
  image: {
    display: "block",
    margin: "20px auto",
    maxWidth: "100%",
    maxHeight: "100px",
  },
  error: {
    color: "red",
    textAlign: "center",
    marginBottom: "15px",
  },
}));

const ModalOfferFast2 = ({ open, setOpen }) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleClose = () => {
    setOpen(false);
    setUrl("");
    setTitle("");
    setImage(null);
    setError("");
  };

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
    setError("");
  };

  const isAmazonUrl = (url) => {
    return /^https?:\/\/(www\.)?(amazon\.(com|es|de|co\.uk|fr|it|ca|com\.mx)|amzn\.to|a\.co|amzn\.com)(\/.*)?$/.test(url);
  };

  const fetchImageAsFile = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const file = new File([blob], "scraped_image.jpg", { type: blob.type });
      return file;
    } catch (error) {
      console.error("Error downloading image:", error);
      setError("Error al descargar la imagen.");
    }
  };

  const fetchData = async () => {
    if (!url) {
      setError("La URL no puede estar vacía");
      return;
    }
    if (!isAmazonUrl(url)) {
      setError("Por favor, ingresa un enlace válido de Amazon");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(`/api/scrapeData2?url=${encodeURIComponent(url)}`);
      setTitle(response.data.title);
      if (response.data.image) {
        const imageFile = await fetchImageAsFile(response.data.image);
        setImage(imageFile);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error al obtener los datos.");
      setTitle("");
      setImage(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (url && open) {
      fetchData();
    }
  }, [url, open]);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 className="text-center">Ingresar URL para crear oferta rapida...</h2>
      <div className="alert alert-warning" role="alert">
        Antes de crear ofertas rápidas por favor revisar las variables de entorno con la tienda y categoría estén
        configuradas...
      </div>
      <form className={classes.form} onSubmit={(e) => e.preventDefault()}>
        <input
          className={classes.input}
          type="text"
          value={url}
          onChange={handleUrlChange}
          placeholder="Pega aquí la URL..."
        />
        <button type="button" onClick={fetchData} disabled={loading || !url}>
          Obtener datos
        </button>
      </form>

      {error && <p className={classes.error}>{error}</p>}
      {loading ? (
        <h3 className="text-center">Cargando...</h3>
      ) : (
        title &&
        image && <FormOfferFast titleOffer={title} urlOffer={url} imageOffer={image} handleClose={handleClose} />
      )}
    </div>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

export default ModalOfferFast2;
