import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { axios } from "../../../request/settings";
import Image from "next/image";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 450,
    height: 350,
    backgroundColor: "white",
    borderRadius: 15,
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down('sm')]: {
      width: 320,
    },
  },
}));

const MenuOffers = ({ open, setOpen }) => {
  const [data, setData] = useState();
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const handleClose = () => {
    setOpen(false);
  };

  const fetchOffers = async () => {
    const { data } = await axios.get(`/offers/pending`);
    setData(data);
  };

  useEffect(() => {
    fetchOffers();
    return [];
  }, []);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h1>Ofertas para publicar</h1>
      <div className="row" style={{ height: 250, overflowY: 'auto', scrollbarWidth: 'none' }}>
        <div className="col-12 mb-3" >
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Imagen</th>
                <th scope="col">Oferta</th>
              </tr>
            </thead>
            <tbody>
              {data && data.map((date) => {
              return (
                <tr key={`alert-offer-${date.id}`}>
                  <th scope="row">
                    <Image
                      src={date.image_url || ""}
                      alt={date.title}
                      width={50}
                      height={30}
                      quality={10}
                      objectFit="contain"
                      placeholder="blur"
                      blurDataURL="/statics/img/logo.jpeg"
                    />
                  </th>
                  <td>{date.title}</td>
                </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {data && data.length === 0 ? <section className="alert alert-danger text-center fw-bold pt-5" style={{ height: '80px' }}>No hay ofertas que mostrar.</section> : null}
      </div>
    </div>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

export default MenuOffers;
