import {BASE_URL, axios } from '../settings'

export const createOffer = async (data) => {
  const formData = new FormData();
  formData.append("offer[type_element]", "offers");
  formData.append("offer[url]", data.url);
  formData.append("offer[title]", data.title);
  formData.append("offer[description]", data.description);
  formData.append("offer[actual_price]", data.actual_price);
  formData.append("offer[normal_price]", data.normal_price);
  formData.append("offer[shipping_cost]", data.shipping_cost);
  formData.append("offer[code]", data.code);
  formData.append("offer[init_date]", data.init_date);
  formData.append("offer[end_date]", data.end_date);
  formData.append("offer[image]", data.image);
  formData.append("offer[category_ids]", data.category_ids);
  const response = await axios.post(`${BASE_URL}/offers`, formData );
  return response;
};

export const createOfferFast = async (data) => {
  const formData = new FormData();
  formData.append("offer[type_element]", "offers");
  formData.append("offer[url]", data.url);
  formData.append("offer[title]", data.title);
  formData.append("offer[description]", data.description);
  formData.append("offer[actual_price]", data.actual_price);
  formData.append("offer[normal_price]", data.normal_price);
  formData.append("offer[shipping_cost]", data.shipping_cost);
  formData.append("offer[code]", data.code);
  formData.append("offer[init_date]", data.init_date);
  formData.append("offer[end_date]", data.end_date);
  formData.append("offer[image]", data.image);
  formData.append("offer[user_aproved_id]", data.user_aproved_id);
  formData.append("offer[aproved]", data.aproved);
  formData.append("offer[shop_id]", data.shop_id);
  formData.append("offer[ranking]", data.ranking);
  formData.append("offer[applies_shipping]", data.applies_shipping);
  formData.append("offer[category_ids]", data.category_ids);
  const response = await axios.post(`${BASE_URL}/offers/create_offer_fast`, formData );
  return response;
};

export const getOffers = async (type, page) => {
  const response = await fetch(`${BASE_URL}/offers?order=${type}&page=${page}`);
  const offers = await response.json();
  return offers
}

export const getOffer = async (id) => {
  const response = await fetch(`${BASE_URL}/offers/${id}`);
  const offer = await response.json();
  return offer;
};

export const getOffersIds = async () => {
  const response = await fetch(`${BASE_URL}/offers/offers_ids`);
  const offers = await response.json();
  return offers;
};

export const setIp = async (elm, id, element) => {
  const data = { validation: { ip: elm, offer_id: id, element: element}}
  await fetch(`${BASE_URL}/validations`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
}