import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import styled from '@emotion/styled';
import { axios } from "../../request/settings";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 450,
    height: 350,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 15,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down('sm')]: {
      width: 320,
    },
  },
}));

const BoxAlerts = styled.div`
  height: 250px;
  overflow-y: auto;
  ::-webkit-scrollbar {
      width: 2px;
      height: 5px;
    }
  ::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:active {
    background-color: gray;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: gray;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }
  ::-webkit-scrollbar-track {
    background: #e1e1e1;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-track:hover,
  ::-webkit-scrollbar-track:active {
    background: #d4d4d4;
  }
  & div {
    border: 2px solid #dc3545;
    border-radius: 8px;
    height: auto;
    background: white;
  }
`;

const ModalAlert = ({ open, setOpen, setCountAlerts }) => {
  const [data, setData] = useState();
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const handleClose = () => {
    setOpen(false);
  };

  const dateInfo = (dateAString) => {
    const currentDate = new Date();
    const dateReceived = new Date(dateAString);
    const difference = dateReceived - currentDate;
    const dateDifference = Math.ceil(difference / (1000 * 60 * 60 * 24));

    if (dateDifference < 0) {
      return <section className="alert alert-danger fw-bold text-center">El cupón ya esta vencido</section>;
    } else if (dateDifference === 0) {
      return <section className="alert alert-warning fw-bold text-center">Hoy es el último día</section>;
    } else {
      return <section className="alert alert-success fw-bold text-center">Faltan {dateDifference} días para que se venza el cupón</section>;
    };
  };

  useEffect(() => {
    const getOffer = async () => {
      const response = await axios.get(`/coupons/alerts_for_coupons`);
      setData(response.data);
      setCountAlerts(response.data.length);
    }
    getOffer();
  }, [open])

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h1>Alertas Cupones &#40;{data && data.length}&#41;</h1>
      <BoxAlerts className="row">
        {data && data.map((alert) => {
          return (
            <div className="col-12 mb-3" key={`alert-id-${alert.id}`}>
              <p><span className="fw-bold">Cupón con id: </span>{alert.id}</p>
              <p><span className="fw-bold">Vencimiento:</span> {alert.end_date}</p>
              <p className="fw-bold">{alert.title}</p>
              <p style={{ color: 'red' }}>{dateInfo(alert.end_date)}</p>
            </div>
          );
        })}
        {data && data.length === 0 ? <section className="alert alert-danger text-center fw-bold pt-5" style={{ height: '80px' }}>No hay alertas que mostrar.</section> : null}
      </BoxAlerts>
    </div>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

export default ModalAlert;
