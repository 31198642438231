export function buildUrl(title, id) {
  const ti = buildTitle(title);
  const url = `${ti}-${id}`;
  return url;
}
export function buildTitle(title) {
  let re = /[+,-,---,!,%,.,(,),&,$,#,¡/]/g;
  let build = title.replace(re, "");
  build = build.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  build = build.replace(/  /g, " ").trim();
  build = build.replace(/ /g, "-").toLowerCase();
  return build;
}

export function firstWrong(text) {
  if (text < 156) {
    return text;
  } else {
    const textView = text.slice(0, 156);
    return textView;
  }
}


// save to local storage with expiry
export function setWithExpiry(key, value, ttl) {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };

  if (typeof window !== 'undefined') {
    window.localStorage.setItem(key, JSON.stringify(item));
  }
}

// get from local storage with expiry
export function getWithExpiry(key) {
  if (typeof window !== 'undefined') {
    const itemStr = window.localStorage.getItem(key);

    if (!itemStr) return null;

    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
      window.localStorage.removeItem(key);
      return null;
    }

    return item.value;
  }
  return null;
}

export function deleteLocalCache(key) {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem(key);
  }
}
