import React, { useState, useEffect } from "react";
import MultiSelect from "react-multi-select-component";
import { Container, Form, InputSubmit, BoxForm, BoxQuill, BoxMultiselect, ButtonLoading } from "../../admin_ui/Form";
import { useUxHandler } from "../../../context/uxHandlerContext";
import useCategories from "../../../hooks/useCategories";
import { createOfferFast } from "../../../request/home/offers";
import { useUser } from "../../../context/userContext";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Campo } from "../../admin_ui/Form";
import "quill/dist/quill.snow.css";
import TextEditor from "components/shared/TextEditor";
import Switch from "@material-ui/core/Switch";

const overrideStrings = {
  selectSomeItems: "Selecciona",
  allItemsAreSelected: "Todos los elementos están seleccionados..",
  selectAll: "Seleccionar todo",
  search: "Buscar",
  clearSearch: "Borrar búsqueda",
};

const FormOfferFast = ({ titleOffer, urlOffer, imageOffer, handleClose }) => {
  const { user } = useUser();
  const [fileUrl, setFileUrl] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const { setAlert } = useUxHandler();
  const randomRanking = Math.floor(Math.random() * (35 - 25 + 1)) + 25;
  const idShopDefault = process.env.NEXT_PUBLIC_SHOP_DEFAULT;
  const idCategoryDefault = process.env.NEXT_PUBLIC_CATEGORY_DEFAULT;
  const enableCreation = process.env.NEXT_PUBLIC_CREATE_OFFER_FAST === 'true';

  const [descriptionCounter, setDescriptionCounter] = useState(0);
  const [descriptionText, setDescriptionText] = useState("");
  const [descriptionContent, setDescriptionContent] = useState("");
  const [viewSend, setViewSend] = useState(false);
  const [viewSend2, setViewSend2] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState([]);
  const categories = useCategories();

  const [errors, setErrors] = useState([false, false, false, false]);

  const optionsCategory = categories
    .map((e) => ({ label: e.name, value: e.id }))
    .filter((elm) => elm.label !== "cupones");

  const defaultData = {
    url: urlOffer,
    title: titleOffer,
    description: "",
    actual_price: "",
    normal_price: "",
    shipping_cost: "",
    init_date: "",
    end_date: "",
    code: "",
    image: "",
    user_aproved_id: user.id,
    aproved: true,
    applies_shipping: false,
    shop_id: parseInt(idShopDefault),
    ranking: parseInt(randomRanking),
    category_ids: [parseInt(idCategoryDefault)],
  };

  const [data, setData] = useState(defaultData);

  useEffect(() => {
    setData({
      ...data,
      category_ids: selectedCategory.map((e) => e.value),
    });
  }, [selectedCategory]);

  useEffect(() => {
    const today = new Date();
    const formattedToday = today.toISOString().split("T")[0];

    const futureDate = new Date();
    futureDate.setDate(today.getDate() + 8);
    const formattedFutureDate = futureDate.toISOString().split("T")[0];

    setData({
      ...defaultData,
      url: urlOffer,
      title: titleOffer,
      init_date: formattedToday,
      end_date: formattedFutureDate,
      image: imageOffer,
    });
  }, []);

  useEffect(() => {
    if (imageOffer) {
      const imageUrl = URL.createObjectURL(imageOffer);
      setFileUrl(imageUrl);
      // Limpia la URL cuando se desmonta el componente
      return () => URL.revokeObjectURL(imageUrl);
    }
  }, [imageOffer]);

  useEffect(() => {
    if (data.shipping_cost === "" || data.shipping_cost === -1 || data.shipping_cost < 0) {
      setViewSend2(false);
    } else {
      setViewSend2(true);
    }
  }, [data.shipping_cost]);

  const getData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const getCheckSearch = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.checked,
    });
    setViewSend((prevState) => !prevState);
  };

  const sendForm = async (e) => {
    e.preventDefault();
    if (data.url.length === 0) {
      setErrors([true, false, false, false]);
      return
    }
    if (data.title.length === 0) {
      setErrors([false, true, false, false]);
      return
    }
    if (!data.image) {
      setErrors([false, false, true, false]);
      return
    }
    if(!data.shop_id || !Number.isInteger(Number(data.shop_id))) {
      setAlert({ isActive: true, title: '!Oops error con la tienda!', message: 'No podemos crear tu oferta debido a que no hay una tienda asignada, verifica tus variables de entorno.', type: 'warning' });
      return
    }
    if (
      !Array.isArray(data.category_ids) || 
      data.category_ids.length === 0 ||
      data.category_ids.some((id) => !Number.isInteger(id))
    ) {
      setAlert({
        isActive: true,
        title: '!Oops error con la categoria!',
        message: 'No podemos crear tu oferta debido a que no hay una categoria asignada, verifica tus variables de entorno.',
        type: 'warning',
      });
      return
    }
    setDisabled(true)
    const record = { ...data, description: descriptionContent };
    await createOfferFast(record);
    setAlert({ isActive: true, title: '!Muy bien!', message: 'La oferta fue creada con éxito para edición y aprobación.', type: 'success' });
    setDisabled(false);
    handleClose();
  };

  return (
    <Container>
      <BoxForm>
        <Form onSubmit={sendForm}>
          <fieldset>
            <div className="offer-header row">
              <div className="offer-info col-12 col-lg mt-5">
                <legend>
                  <strong>Información general</strong>
                </legend>
                <div className="offer-url form-group">
                  <label htmlFor="url">
                    <small>Sitio web</small>
                  </label>
                  <input
                    className="form-control form-control-lg"
                    type="url"
                    id="url"
                    value={urlOffer}
                    placeholder="Ingresa la dirección web de la oferta"
                    name="url"
                    disabled
                  />
                  {errors[0] ? <div className="alert alert-danger h5" role="alert">La url no puede ir vacía.</div> : null}
                </div>
                <div className="offer-title form-group">
                  <label htmlFor="title">
                    <small>Nombre de la oferta</small>
                  </label>
                  <input
                    className="form-control form-control-lg"
                    type="text"
                    id="title"
                    value={data.title || ""}
                    placeholder="Escribe el nombre de la oferta"
                    name="title"
                    onChange={getData}
                  />
                  {errors[1] ? <div className="alert alert-danger h5" role="alert">El nombre no puede ir vacío o superar los 90 caracteres.</div> : null}
                </div>
                <div className="form-group">
                  <div>
                    <label>Imagen:</label>
                    {fileUrl && <img src={fileUrl} alt="Vista previa" style={{ maxWidth: "100%", maxHeight: "150px", marginTop: "10px" }} />}
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="description">
                <small>Descripción de la oferta</small>
              </label>
              <BoxQuill style={{ minHeight: 280, background: 'white' }}>
                <TextEditor
                  text={descriptionContent}
                  onTextChange={(text) => {
                    setDescriptionText(text);
                    setDescriptionCounter(text.length);
                  }}
                  onContentChange={(content) => {
                    setDescriptionContent(JSON.stringify(content));
                  }}
                />
              </BoxQuill>
              {errors[3] ? <div className="alert alert-danger h5" role="alert">La descripción debe tener mínimo 450 caracteres.</div> : null}
            </div>
          </fieldset>
          <fieldset>
            <legend>
              <strong>Detalles de la oferta</strong>
            </legend>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="actual_price">
                    <small>Precio Actual</small>
                  </label>
                  <input
                    className="form-control form-control-lg"
                    type="number"
                    id="actual_price"
                    value={data.actual_price}
                    placeholder="Escribe el precio actual"
                    name="actual_price"
                    onChange={getData}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="normal_price">
                    <small>Precio Normal</small>
                  </label>
                  <input
                    className="form-control form-control-lg"
                    type="number"
                    id="normal_price"
                    value={data.normal_price}
                    placeholder="Escribe el precio normal"
                    name="normal_price"
                    onChange={getData}
                  />
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset style={{ border: '2px solid green', borderRadius: '10px' }}>
            <legend>
              <strong>Envíos {'(Leer los valores de envío)'}</strong>
            </legend>
            <div className="row">
              <div className="col-6">
                <Campo className="form-group">
                  <FormControlLabel
                    label="Aplica envío elegible?"
                    control={
                      <Switch
                        checked={data.applies_shipping || false}
                        onChange={getCheckSearch}
                        color="primary"
                        name="applies_shipping"
                        inputProps={{ "aria-label": "checkbox with default color" }}
                        disabled={viewSend2}
                      />
                    }
                  />
                </Campo>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="shipping_cost">
                    <small>Costo de envió</small>
                  </label>
                  <input
                    className="form-control form-control-lg"
                    type="number"
                    id="shipping_cost"
                    value={data.shipping_cost}
                    placeholder="Escribe el costo de envio"
                    name="shipping_cost"
                    onChange={getData}
                    min= "-1"
                    disabled={viewSend}
                  />
                <p className="alert alert-danger h5">Si dejas en blanco o un numero negativo aquí, se habilitara el aplicar envío elegible</p>
                </div>
                <p className="mt-3 fw-bold">Valores para envíos</p>
                <ol type="1" className="alert alert-warning h4">
                  <li>Valor {'(-1 o vacío)'}= No se vera etiqueta</li>
                  <li>Valor 0 = Etiqueta Envío Gratis</li>
                  <li>Valor {'>'} 0 = Etiqueta Con costo de envío</li>
                </ol>
              </div>
            </div>
          </fieldset>
          <fieldset className="d-none">
            <legend>
              <strong>Categorías de la oferta</strong>
            </legend>
            <BoxMultiselect className="form-group">
              <label>
                <small>Categorías</small>
              </label>
              <div>
                <p>
                  {selectedCategory.map((e) => (
                    <small key={e.value}>&#8227; {e.label} </small>
                  ))}
                </p>
                <MultiSelect
                  options={optionsCategory}
                  value={selectedCategory}
                  onChange={setSelectedCategory}
                  labelledBy={"Selecciona las Categorías de la oferta"}
                  className="multiselects"
                  overrideStrings={overrideStrings}
                />
              </div>
            </BoxMultiselect>
          </fieldset>
          <fieldset>
            <legend>
              <strong>Detalles inicio de la oferta</strong>
            </legend>
            <div className="row">
              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label htmlFor="init_date">
                    <small>Fecha de Inicio</small>
                  </label>
                  <input
                    className="form-control form-control-lg"
                    type="date"
                    id="init_date"
                    name="init_date"
                    value={data.init_date}
                    disabled
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label htmlFor="end_date">
                    <small>Fecha de Fin</small>
                  </label>
                  <input
                    className="form-control form-control-lg"
                    type="date"
                    id="end_date"
                    placeholder="Escribe el precio normal"
                    value={data.end_date}
                    name="end_date"
                    disabled
                  />
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <legend>
              <strong>Código de descuento &#40;opcional&#41;</strong>
            </legend>
            <div className="form-group">
              <label htmlFor="code">
                <small>Código</small>
              </label>
              <input
                className="form-control form-control-lg"
                type="text"
                id="code"
                placeholder="Ingresa el código de descuento"
                name="code"
                onChange={getData}
              />
            </div>
          </fieldset>
          <fieldset>
            <legend>
              <strong>Ranking</strong>
            </legend>
            <div className="form-group">
              <input
                className="form-control form-control-lg"
                type="number"
                id="ranking"
                name="ranking"
                defaultValue={randomRanking}
                disabled
              />
            </div>
          </fieldset>
          <div className="text-center mb-3">
            {disabled ? (
              <ButtonLoading className="btn btn-success btn-lg m-auto" type="button" disabled>
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                Loading...
              </ButtonLoading>
            ) : (
              <InputSubmit
                className="btn btn-lg btn-block"
                type="submit"
                value="Crear Oferta"
                disabled={!enableCreation}
              />
            )}
          </div>
        </Form>
      </BoxForm>
    </Container>
  );
};

export default FormOfferFast;
