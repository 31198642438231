import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { axios } from "../../../request/settings";
import BoxEditorCoupon from "../../home/coupon/BoxEditorCoupon";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 450,
    height: 350,
    backgroundColor: "white",
    borderRadius: 15,
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down('sm')]: {
      width: 320,
    },
  },
}));

const MenuComments = ({ open, setOpen }) => {
  const [data, setData] = useState();
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const getComments = async () => {
      const { data } = await axios.get(`/comments/pending`);
      setData(data);
    };
    getComments();
  }, []);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h1>Comentarios para publicar</h1>
      <div className="row" style={{ height: 250, overflowY: 'auto', scrollbarWidth: 'none' }}>
        <div className="col-12 mb-3" >
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">comentario</th>
              </tr>
            </thead>
            <tbody>
              {data && data.map((date) => {
              return (
                <tr key={`alert-comment-${date.id}`}>
                  <th scope="row">{date.id}</th>
                  <td>
                    <BoxEditorCoupon text={date.info} />
                  </td>
                </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {data && data.length === 0 ? <section className="alert alert-danger text-center fw-bold pt-5" style={{ height: '80px' }}>No hay Comentarios que mostrar.</section> : null}
      </div>
    </div>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

export default MenuComments;
