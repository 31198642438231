import { useEffect } from "react";
import { useQuill } from "react-quilljs";
import Delta from "quill-delta";

import { BASE_URL } from "@request/settings";

import "quill/dist/quill.snow.css";

export default function TextEditor({ text = "", onTextChange, onContentChange, onHtmlChange }) {
  const assetsEPUrl = `${BASE_URL}/assets`;

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      ["link", "image", "video"],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      ["clean"],
    ],
    blotFormatter: {},
    magicUrl: true,
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "color",
    "background",
  ];

  const { quill, quillRef, Quill } = useQuill({
    modules,
    formats,
  });

  if (Quill && !quill) {
    const BlotFormatter = require("quill-blot-formatter").default; // Install with 'yarn add quill-blot-formatter'
    Quill.register("modules/blotFormatter", BlotFormatter);

    const MagicUrl = require("quill-magic-url").default; // Install with 'yarn add quill-magic-url'
    Quill.register("modules/magicUrl", MagicUrl);
  }

  // Insert Image(selected by user) to quill
  const insertToEditor = (url) => {
    const range = quill.getSelection();
    quill.insertEmbed(range.index, "image", url);
  };

  // Upload Image to Image Server such as AWS S3, Cloudinary, Cloud Storage, etc..
  const saveToServer = async (file) => {
    const body = new FormData();
    body.append("asset[image]", file);

    const res = await fetch(assetsEPUrl, { method: "POST", body });
    const imageData = await res.json();

    insertToEditor(imageData.url);
  };

  const uploadBase64Image = async (base64) => {
    const body = new FormData();
    body.append("asset[image]", base64);
    const ep = `${assetsEPUrl}?type=base64`;
    const res = await fetch(ep, { method: "POST", body });
    const imageData = await res.json();

    return imageData.url;
  };

  // Open Dialog to select Image File
  const selectLocalImage = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      saveToServer(file);
    };
  };

  const isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (quill) {
      if (isJsonString(text)) {
        quill.setContents(JSON.parse(text));
      } else {
        quill.clipboard.dangerouslyPasteHTML(text);
      }

      quill.clipboard.quill.getModule("toolbar").addHandler("image", selectLocalImage);

      quill.clipboard.addMatcher("img", (node, delta) => {
        // upload image from node.src:
        if (!node.src.startsWith("data:image")) {
          return delta;
        }

        uploadBase64Image(node.src).then((url) => {
          quill.updateContents(
            new Delta().retain(quill.getSelection()?.index ?? 0).insert({
              image: url,
            }),
          );
        });

        return new Delta().insert(node, { image: node.src });
      });

      quill.on("text-change", (delta, oldDelta, source) => {
        onTextChange && onTextChange(quill.getText().trim());
        onHtmlChange && onHtmlChange(quill.root.innerHTML);
        onContentChange && onContentChange(quill.getContents());
      });
    }
  }, [quill]);

  return (
    <div className="text-editor">
      <div ref={quillRef} />
    </div>
  );
}
