import React, { useState, useEffect } from "react";
import { BASE_URL } from "../request/settings";

const useCategories = (type = '') => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const getCategories = async () => {
      const url = type !== '' ? `${BASE_URL}/categories?type=${type}` : `${BASE_URL}/categories`
      const response = await fetch(url);
      const data = await response.json();
      setCategories(data);
    };
    getCategories();
  }, []);

  return categories;
};

export default useCategories;
