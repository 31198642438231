import styled from "@emotion/styled";

export const Container = styled.section`
  max-width: 1280px;
  height: auto;
  margin: auto;
  @media (max-width: 768px) {
    width: 95%;
  }
`;

export const Form = styled.form`
  width: 100%;
  position: relative;
`;

export const Campo = styled.div`
  margin-bottom: 2rem;
  label {
    flex: 0 0 150px;
    font-size: 1.6rem;
  }
  input,
  textarea {
    flex: 1;
    padding: 1rem;
  }
  textarea {
    height: 250px;
  }
`;

export const Preview = styled.div`
  background: none;
  padding: 10px;
  width: 100%;
  margin-bottom: 30px;
  height: 100%;
  filter: grayscale(1);
`
export const BoxOfferPreview = styled.div`
  margin: 0px 50px;
  filter: grayscale(1);
  @media (max-width: 575px) {
    margin: 0px;
  }
  //border: 1px solid red;
`

export const BoxVotesPreview = styled.div`
  @media (max-width: 575px) {
    display: flex;
    justify-content: center;
  }
`;

export const BoxForm = styled.div`
  width: 100%;
  padding: 20px;
  background: none;
  ::-webkit-scrollbar {
    width: 2px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--morado4);
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:active {
    background-color: var(--morado4);
  }
  ::-webkit-scrollbar-thumb:hover {
    background: var(--morado2);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }
  ::-webkit-scrollbar-track {
    background: #e1e1e1;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-track:hover,
  ::-webkit-scrollbar-track:active {
    background: #d4d4d4;
  }
  @media (max-width: 992px) {
    overflow-y:auto;
  }
`;

export const InputSubmit = styled.input`
  position: relative;
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 15px;
  background: black;
  width: 200px;
  height: 45px;
  margin: auto;
  padding: 0.5rem;
  color: #fff;
  font-size: 1.6rem;
  border: 1px solid var(--gris);
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  border-radius: 10px;
  display: block;
  transition: all 0.3s;
  &:hover {
    cursor: pointer;
    background-color: white;
    border: 1px solid var(--gris);
    color: black;
    transition: all 0.3s;
  }
`;

export const ButtonLoading = styled.button`
  background: black !important;
  width: 200px;
  height: 45px;
  border: 1px solid var(--gris) !important;
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  border-radius: 10px;
`;

export const BoxMultiselect = styled.div`
  border-radius: 4px;
  background-color: white;
  & .dropdown-heading {
    border: 1px solid #ccc;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 15px;
    cursor: pointer;
  }
  & .gray {
    font-size: 12px;
    color: gray !important;
  }
  & label {
    display: block;
    margin-bottom: 8px;
  }
  & label small {
    font-size: 20px;
  }
  & .dropdown-content {
    position: relative;
    padding-top: 0px;
    background-color: white;
    overflow-y: auto;
    width: 100%;
    z-index: 1;
    box-shadow: none;
    border: 1px solid #ccc;
  }
  & .panel-content {
    padding: 10px 15px;
  }
  & .select-panel input {
    border: 1px solid #ccc;
  }
  & .select-panel input::placeholder {
    font-size: 14px;
  }
`;

export const BoxQuill = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #d1d5db;
  border-radius: 5px;
  .ql-container.ql-snow {
    border: none;
    min-height: 120px;
    transition: min-height 0.3s ease;
  }
  .ql-toolbar.ql-snow {
    width: auto;
    border: none;
    display: flex;
    transition: display 0.3s ease;
    @media (max-width: 830px) {
      overflow-x: auto;
      overflow-y: visible;
      border-left: 20px solid white;
      border-right: 25px solid white;
      margin-left: 5px;
      margin-right: 5px;
      &::before {
        content: '<';
        margin-right: 5px;
        padding-left: 6px;
        padding-top: 0px;
        font-weight: bold;
        color: white;
        font-size: 20px;
        position: absolute;
        left: 3px;
        background-color: #d1d5db;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        z-index: 20;
      }
      &::after {
        content: '>';
        margin-right: 5px;
        padding-left: 6px;
        padding-top: 0px;
        font-weight: bold;
        color: white;
        font-size: 20px;
        position: absolute;
        background-color: #d1d5db;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        right: -3px;
        z-index: 20;
      }
      .ql-picker.ql-header {
        display: none;
      }
      .ql-color-picker {
        display: none;
      }
    }
  }
  .ql-snow.ql-toolbar button {
    border: 1px solid #d1d5db;
    margin-right: 3px;
    border-radius: 3px;
    width: 25px;
    height: 25px;
  }
  .ql-toolbar.ql-snow .ql-picker-label {
    border: 1px solid #d1d5db;
    margin-right: 3px;
    border-radius: 3px;
    height: 25px;
  }
  .ql-snow .ql-formats {
    display: flex;
  }
  .ql-tooltip {
    left: -20px !important;
  }
`;
