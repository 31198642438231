import { useEffect, useRef } from 'react'
import { useQuill } from 'react-quilljs'
import styled from '@emotion/styled'
import { firstWrong } from '../../../helpers/commons'

const Box = styled.span`
  padding-left: 0; 
  border: none !important;
  margin-right: 10px;
  display: none;
  .ql-editor{
    padding: 2% 0;
  }
`
const BoxSpan = styled.span`
  font-size: 14px;
`

const BoxEditorCoupon = ({ text, format=true }) => {
  const { quill, quillRef } = useQuill({
    readOnly: true,
    modules: { toolbar: false }
  })
  const spanRef = useRef(null)
  useEffect(() => {
    if (quill) {
      try {
        if(format){
          quillRef.current.style.display = 'block'
          quill.setContents(JSON.parse(text)) 
        }else{
          quill.setContents(JSON.parse(text)) 
          const textStr = quill.getText()
          spanRef.current.innerHTML = firstWrong(textStr)
        }
      } catch (e) {
        spanRef.current.innerHTML = firstWrong(text)
      }
    }
  }, [quill])
  return (
    <>
      <Box ref={quillRef} />
      <BoxSpan ref={spanRef} />
    </>
  )
}

export default BoxEditorCoupon