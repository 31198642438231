import React, { useState } from 'react';
import styled from '@emotion/styled';
import Image from "next/image";
import ModalAlert from '../../admin/ModalAlert';
import MenuOffers from './MenuOffers';
import MenuCoupons from './MenuCoupons';
import MenuComments from './MenuComments';
import ModalOfferFast from '../../admin/ModalOfferFast';
import ModalOfferFast2 from '../../admin/ModalOfferFast2';
import { getPendingCounts } from "@context/commentsContext";

const Nav = styled.nav`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: auto;
  height: 65px;
  margin-left: 240px;
  padding-left: 15px ;
  background-color: white;
  box-shadow: 0 5px 10px rgba(0,0,0,0.5);
  transition: all .3s;
  background-color: #263238;
  @media (max-width: 768px) {
    margin-left: 0px;
    grid-template-columns: 1fr 2fr;
  }
`;

const BoxLeft = styled.div`
  grid-column: 1;
  align-self: center; 
`;

const BoxRight = styled.div`
  grid-column: 2;
  justify-self: end;
  @media (max-width: 768px) {
    width: 120%;
  }
`;

const SpanLink = styled.span`
  position: relative;
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-right: 16px;
  text-align: center;
  font-size: 24px;
  vertical-align: middle;
  background-image: url("/statics/img/icon_menu/more.png");
  background-size: cover;
  filter: invert(1);
  cursor: pointer;
  z-index: 1000;
  @media (max-width: 768px) {
    filter: ${(props) => (props.menuDynamic ? "" : "invert(100%)")};
    transform: translateX(-15px)
  }
`;

const SpanTitle = styled.span`
  display: inline-block;
  position: relative;
  color: white;
  @media (max-width: 768px) {
    display: none;
  }
`;

const BoxAlert = styled.div`
  padding-left: 10px;
  img{
    cursor: pointer;
  }
  button {
    background: white;
  }
  button:hover {
    background: white;
  }
`;

const Header = ({ menuView, menuDynamic }) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [openOffers, setOpenOffers] = useState(false);
  const [openCoupons, setOpenCoupons] = useState(false);
  const [openComments, setOpenComments] = useState(false);
  const [openOfferFast, setOpenOfferFast] = useState(false);
  const [openOfferFast2, setOpenOfferFast2] = useState(false);
  const [countAlerts, setCountAlerts] = useState(0);
  const { pendingComments, offerCount, couponCount } = getPendingCounts();

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleOpenEditOffers = () => {
    setOpenOffers(true);
  };

  const handleOpenEditCoupon = () => {
    setOpenCoupons(true);
  };

  const handleOpenEditComments = () => {
    setOpenComments(true);
  };


  const handleOpenOfferFast = () => {
    setOpenOfferFast(true);
  };

  const handleOpenOfferFast2 = () => {
    setOpenOfferFast2(true);
  };

  return (
    <Nav className={menuDynamic && 'marginAnimation'}>
      <BoxLeft className="display-6 m-4 p-0 pb-4">
        <SpanLink onClick={menuView} menuDynamic={menuDynamic}></SpanLink>
        <SpanTitle>Dashboard</SpanTitle>
      </BoxLeft>
      <BoxRight>
        <BoxAlert className="m-4 ms-0 d-flex align-items-center justify-content-center">
          {/* //test de offer fast for user 7, 1714 and 1690 with rezise */}
          <button type="button" className="btn btn-light position-relative me-3" onClick={handleOpenOfferFast2}>
            <Image src="/statics/img/icon_menu/amazonLogo.png" style={{ display: 'inline-block', background: 'white' }} alt="alerts" width={30} height={30} />
          </button>
          {/* //offer fast */}
          {/* <button type="button" className="btn btn-light position-relative me-3" onClick={handleOpenOfferFast}>
            <Image src="/statics/img/icon_menu/limited-time-offer.png" style={{ display: 'inline-block', background: 'white' }} alt="alerts" width={30} height={30} />
          </button> */}
          <button type="button" className="btn btn-light position-relative me-3" onClick={handleOpenEditOffers}>
            <Image src="/statics/img/icon_menu/etiqueta-de-precio.png" style={{ display: 'inline-block', background: 'white' }} alt="alerts" width={30} height={30} />
            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style={{ fontSize: '10px'}}>{offerCount}</span>
          </button>
          <button type="button" className="btn btn-light position-relative me-3" onClick={handleOpenEditCoupon}>
            <Image src="/statics/img/icon_menu/cupon.png" style={{ display: 'inline-block', background: 'white' }} alt="alerts" width={30} height={30} />
            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style={{ fontSize: '10px'}}>{couponCount}</span>
          </button>
          <button type="button" className="btn btn-light position-relative me-3" onClick={handleOpenEditComments}>
            <Image src="/statics/img/icon_menu/conversacion.png" style={{ display: 'inline-block', background: 'white' }} alt="alerts" width={30} height={30} />
            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style={{ fontSize: '10px'}}>{pendingComments}</span>
          </button>
          <button type="button" className="btn btn-light position-relative" onClick={handleOpenEdit}>
            <Image src="/statics/img/warning.png" style={{ display: 'inline-block', background: 'white' }} alt="alerts" width={30} height={30} />
            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style={{ fontSize: '10px'}}>{countAlerts}</span>
          </button>
        </BoxAlert>
      </BoxRight>
      <ModalAlert open={openEdit} setOpen={setOpenEdit} setCountAlerts={setCountAlerts}/>
      <MenuOffers open={openOffers} setOpen={setOpenOffers} />
      <MenuCoupons open={openCoupons} setOpen={setOpenCoupons} />
      <MenuComments open={openComments} setOpen={setOpenComments} />
      <ModalOfferFast open={openOfferFast} setOpen={setOpenOfferFast} />
      <ModalOfferFast2 open={openOfferFast2} setOpen={setOpenOfferFast2} />
    </Nav>
  );
}

export default Header;