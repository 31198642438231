import React, { Fragment, useEffect, useContext } from 'react';
import { AppContext } from '../../../context/AppContext';
import { useRouter } from 'next/router';
import { useUser } from '../../../context/userContext';
import useQueryTokenAuth from '../../../hooks/useQueryTokenAuth'
import Header from './Header';
import SideNav from './SideNav';
import { Global, css } from '@emotion/react';
import styled from '@emotion/styled';
import Head from 'next/head';

const Content = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 60px);
  overflow:hidden;
`;

const Main = styled.main`
  width: 100%;
  padding-bottom: 3rem;
  overflow-x: auto;
  margin-left: 240px;
  transition: all .3s;
  background-color: var(--gris4);
  ::-webkit-scrollbar {
      width: 2px;
      height: 5px;
    }
  ::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:active {
    background-color: gray;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: gray;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }
  ::-webkit-scrollbar-track {
    background: #e1e1e1;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-track:hover,
  ::-webkit-scrollbar-track:active {
    background: #d4d4d4;
  }
  @media (max-width: 768px) {
    margin-left: 0px;
  }
`;

const ContentLoader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

const Layout = props => {
  const ValueMenu = useContext(AppContext)
  const {user} = useUser();
  const router = useRouter();
  const setToken = useQueryTokenAuth()

  const menuView = () => {
    ValueMenu.setMyState(!ValueMenu.myState)
  };

  const isAuthorizedUser = (user) => {
    return user && ["admin", "editor", "superadmin"].includes(user.role);
  };

  const menuDynamicValue = ValueMenu.myState;

  useEffect(() => {
    setToken
  },[])

  useEffect(() => {
    const chargePage =  () => {
      if (!user || user.role === "user") return router.push("/");
    };
    chargePage();
  }, [user]);

  return (
    <Fragment>
      <Global
        styles={css`
          :root {
            --gris: #3d3d3d;
            --gris2: #6f6f6f;
            --gris3: #e1e1e1;
            --gris4: #e9eaed;
            --naranja: #da552f;
            --azul: #075ea4;
            --azul2: #1E90FF;
            --morado2: #300047; /* morado oscuro*/
            --morado3: #6b326d; /* morado intermedio*/
            --morado4: #6c537e; /* morado claro*/
          }
          html {
            font-size: 62.5%;
            box-sizing: border-box;
            scrollbar-width: none;
          }
          *,*:before, *:after {
            box-sizing: inherit;
          }
          body {
            font-size: 1.6rem;
            line-height: 1.5;
          }
          h1, h2, h3 {
            margin: 0 0 2rem 0;
            line-height: 1.5;
          }
          h1,h2{
            font-family: 'Roboto', serif; 
            font-weight: 700;
          }
          h3{
            font-family: 'PT Sans', sans-serif;
          }
          ul {
            list-style: none;
            margin: 0;
            padding: 0;
          }
          a {
            text-decoration: none;
          }
          img {
            max-width: 100%;
          }
        `} >
      </Global>
      <Head>
        <title>Turbo Offers</title>
      </Head>
      {isAuthorizedUser(user) ?
          <>
            <Header menuView={menuView} menuDynamic={menuDynamicValue} />
            <Content>
              <SideNav menuView={menuView} role={user.role} menuDynamic={menuDynamicValue}/>
              <Main className={menuDynamicValue && 'marginAnimation'}>
                {props.children}
              </Main>
            </Content>
          </>
        :
        <ContentLoader>
          <h1>Cargando....</h1>
        </ContentLoader>
      }     
    </Fragment>
  );
}

export default Layout;
